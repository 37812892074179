@import 'colors';
@import 'constants';
@import 'ball';

.field {
  width: 600px;
  height: 600px;
  padding: 10px;
  border-radius: 6px;
  flex-shrink: 0;
  margin-left: auto;

  @if $debug {
    background-color: mix($color-green-light, $color-green-dark, 10%);
  }
}

.cellContainer {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-gap: 0;
  width: 100%;
  height: 100%;
}

.modeContainer {
  position: relative;
  width: 50px;
  height: 50px;
  bottom: 43px;
  left: 15px;
  z-index: 1;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @if $debug {
    &::after {
      content: '';
      width: 60%;
      height: 60%;
      border-radius: 50%;

      background-image: linear-gradient(
        145deg,
        mix($color-green-light, $color-green-dark, 30%),
        mix($color-green-light, $color-green-dark, 10%)
      );
    }
  }
}

.cell {
  position: relative;
}

.ballType1,
.ballType2 {
  position: absolute;
  left: 0;
  top: 0;
}

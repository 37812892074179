@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
@import 'colors';

.controls {
  font-family: 'Press Start 2P', cursive;
  -webkit-font-smoothing: none;
  overflow: hidden;
  user-select: none;

  color: $color-green-light;
  font-size: 0.75rem;
  font-weight: 200;
  text-transform: uppercase;
  line-height: 1.4;
  text-shadow: 0 0.15em 0 rgba($color-brown, 0.5);

  padding: 0;
  text-align: center;
}

.controls {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
    margin-right: 20px;
  }

  .control {
    text-decoration: none;
    color: $color-green-light;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .underline {
    text-decoration: underline;
  }
}

.blinking {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.status {
  margin-top: 10px;
}

.statusGameover {
  margin-top: 10px;
}

.restartBar {
  margin-top: 10px;
}

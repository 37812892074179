@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
@import 'colors';

.statsContainer {
  display: flex;

  font-family: 'Press Start 2P', cursive;
  -webkit-font-smoothing: none;
  overflow: hidden;
  user-select: none;

  color: $color-cream;
  font-size: 0.75rem;
  font-weight: 200;
  text-transform: uppercase;
  line-height: 1.4;
  text-shadow: 0 0.15em 0 rgba($color-brown, 0.5);

  padding: 10px 40px 10px 10px;
  width: calc(100% - 50px);
  margin-left: 30px;
  margin-top: 15px;
}

.level {
  flex-basis: 25%;
  text-align: left;
  overflow: hidden;
}

.score {
  flex-basis: 25%;
  text-align: right;
  overflow: hidden;
}

.controls {
  flex-basis: 50%;
  text-align: center;
  overflow: hidden;
}

.difficulty {
  color: $color-green-light;
}

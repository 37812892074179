@import 'colors';
@import 'ball';

.board {
  width: 600px;
  height: 600px;
  background-color: $color-cream;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 6px 0 mix($color-cream, $color-brown, 50%);
  flex-shrink: 0;
  margin-right: auto;
}

.cellContainer {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-gap: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.row {
  display: contents;
  position: relative;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    width: 60%;
    height: 60%;
    border-radius: 50%;

    box-shadow: 3px 3px 10px 0px mix($color-cream, $color-brown, 70%) inset,
      -4px -4px 3px 0 mix($color-cream, #fff, 99%) inset;

    background-image: linear-gradient(
      145deg,
      mix($color-cream, $color-brown, 90%),
      $color-cream
    );
  }
}

.hover {
  &::after {
    content: '';
    width: 60%;
    height: 60%;
    border-radius: 50%;

    box-shadow: 2px 2px 5px #00000030;

    background-image: radial-gradient(
      circle at 40% 40%,
      mix($color-cream, $color-brown, 90%) 5%,
      mix($color-cream, $color-brown, 20%) 50%,
      mix($color-cream, $color-brown, 10%) 80%
    );
  }
}

.cell {
  position: relative;
}

.ballType1,
.ballType2 {
  position: absolute;
  left: 0;
  top: 0;
}

.tourThirdStep {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}

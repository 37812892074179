@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
@import 'colors';

.mode {
  font-family: 'Press Start 2P', cursive;
  -webkit-font-smoothing: none;
  overflow: hidden;
  user-select: none;

  color: $color-cream;
  font-size: 0.75rem;
  font-weight: 200;
  text-transform: uppercase;
  line-height: 1.4;
  text-shadow: 0 0.15em 0 rgba($color-brown, 0.5);
}

.controls {
  font-family: 'Press Start 2P', cursive;
  -webkit-font-smoothing: none;
  overflow: hidden;
  user-select: none;

  color: $color-green-light;
  font-size: 0.75rem;
  font-weight: 200;
  text-transform: uppercase;
  line-height: 1.4;
  text-shadow: 0 0.15em 0 rgba($color-brown, 0.5);
}

.control {
  text-decoration: none;
  color: $color-green-light;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.underline {
  text-decoration: underline;
}

.difficulty {
  color: $color-green-light;
}
// https://coolors.co/palette/da2c38-226f54-87c38f-f4f0bb-43291f
$color-red: #da2c38;
$color-green-dark: #226f54;
$color-green-light: #87c38f;
$color-cream: #f4f0bb;
$color-coffee: #7b4b39;
$color-brown: #43291f;

// https://coolors.co/palette/10002b-240046-3c096c-5a189a-7b2cbf-9d4edd-c77dff-e0aaff
$color-tour-background: #3c096c;
$color-tour-bg-highlight: #5a189a;
$color-tour-font-color: #e0aaff;
$color-tour-note-color: #c77dff;
$color-tour-strong-color: #fafafa;
$color-tour-font-shadow: #888;

:root {
  --reactour-accent: #da2c38;
}

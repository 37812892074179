.ballType1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    width: 60%;
    height: 60%;
    border-radius: 50%;

    box-shadow: 2px 2px 5px #00000030;

    background-image: radial-gradient(
      circle at 40% 40%,
      mix($color-cream, $color-coffee, 90%) 5%,
      mix($color-cream, $color-coffee, 40%) 50%,
      mix($color-cream, $color-brown, 10%) 80%
    );
  }
}

.ballType2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    width: 60%;
    height: 60%;
    border-radius: 50%;

    box-shadow: 2px 2px 5px #00000030;

    background-image: radial-gradient(
      circle at 40% 40%,
      mix($color-cream, $color-brown, 90%) 5%,
      mix($color-cream, $color-brown, 20%) 50%,
      mix($color-cream, $color-brown, 10%) 80%
    );
  }
}

@keyframes explosionAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    background-color: transparent;
    transform: scale(0);
  }
}

.explosion {
  position: relative;
  animation: explosionAnimation 0.5s forwards;
}

@import 'colors';

body {
  background-color: $color-green-dark;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container2,
.container3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tour {
  background-color: $color-tour-background !important;

  font-family: 'Press Start 2P', cursive;
  -webkit-font-smoothing: none;
  overflow: hidden;
  user-select: none;

  font-size: 0.5rem;
  font-weight: 200;
  text-transform: uppercase;
  line-height: 1.4;
  color: $color-tour-font-color !important;
  u {
    background-color: $color-tour-bg-highlight;
    text-decoration: none;
  }
}
.tourTitle {
  margin-bottom: 5px;
  color: $color-tour-strong-color !important;
  text-shadow: 0 0.15em 0 rgba($color-tour-font-shadow, 0.5) !important;
}
.tourNote {
  color: $color-tour-note-color !important;
}
.tourClose {
  text-decoration: underline;
  text-align: right;
  cursor: pointer;
  animation: blink 2s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
